import jQuery from 'jquery';

(function($) {
  /**
   * Handling of the mobile navigation page switching.
   *
   * @class MobileNavigation
   * @constructor
   */
  function MobileNavigation(mobileNav, options) {
    /**
     * jQuery reference to all navigation blocks.
     *
     * @type {Object}
     */
    var navBlocks;

    /**
     * The index of the currently visible navigation block.
     *
     * @type {number}
     */
    var activeNavBlockIndex;

    /**
     * jQuery reference to the "up" button.
     *
     * @type {Object}
     */
    var controlUp;

    /**
     * jQuery reference to the "down" button.
     *
     * @type {Object}
     */
    var controlDown;

    /**
     * If TRUE no actions will be executed when a button is clicked. This
     * is used during fading / animations to prevent unwanted side effects.
     *
     * @type {boolean}
     */
    var disableActions = false;

    /**
     * This is TRUE when the page swiching was initialized.
     *
     * @type {boolean}
     */
    var initialized = false;

    /**
     * The media query that is used to determine if the mobile navigation is used.
     *
     * @type {string}
     */
    var mediaQuery = '(max-width: ' + options.mediaQueryMaxWidth + 'px)';

    /**
     * Handles the click on one of the up / down buttons.
     *
     * @method
     * @param {Object} otherButton The button that was NOT clicked.
     * @param {Number} direction The direction in which the user has clicked (+1 or -1).
     * @return {Boolean} TRUE when disableActions is FALSE and the action was executed, FALSE otherise.
     */
    var handleButtonClick = function(otherButton, direction) {

      if (disableActions) {
        return false;
      }
      disableActions = true;

      var previousActiveBlockIndex = activeNavBlockIndex;
      activeNavBlockIndex += direction;

      navBlocks.eq(previousActiveBlockIndex).hide();
      navBlocks.eq(activeNavBlockIndex).show();

      // noinspection JSValidateTypes
      mobileNav.css({height: navBlocks.eq(activeNavBlockIndex).outerHeight()});
      otherButton.show();

      disableActions = false;

      return true;
    };

    /**
     * Sets the initial height of the navigation container depending
     * on the height of the first visible navigation block.
     *
     * @method
     * @return {void}
     */
    var setInitialHeightOfOuterContainer = function() {

      // Workaround to get the height of the inner navbar blocks.
      var outerNavbar = mobileNav.parents('.navbar-collapse').first();
      var outerNavbarCss = outerNavbar.attr('style');
      outerNavbar.css({display: 'block', visibility: 'hidden'});

      // noinspection JSValidateTypes
      mobileNav.css('height', navBlocks.eq(activeNavBlockIndex).outerHeight(true));

      // Restore the original CSS.
      outerNavbar.attr('style', outerNavbarCss || '');
    };

    /**
     * Initializes the click events on the up / down buttons.
     *
     * @method
     * @return {void}
     */
    var initializeClickEvents = function() {

      controlUp.click(function() {

        if (!handleButtonClick(controlDown, -1)) {
          return;
        }

        if (activeNavBlockIndex === 0) {
          controlUp.hide(options.effectDuration);
        }
      });

      controlDown.click(function() {

        if (!handleButtonClick(controlUp, 1)) {
          return;
        }

        if (activeNavBlockIndex === (navBlocks.length - 1)) {
          controlDown.hide(options.effectDuration);
        }
      });
    };

    /**
     * Initializes the navigation block switching.
     *
     * @method
     * @return {void}
     */
    var initialize = function() {

      if (initialized) {
        return;
      }

      initialized = true;

      navBlocks = mobileNav.find('.tx-intdiv-mobile-nav-block');
      if (!navBlocks.length) {
        return;
      }

      controlUp = mobileNav.find('.tx-intdiv-mobile-nav-control-up');
      controlDown = mobileNav.find('.tx-intdiv-mobile-nav-control-down');

      activeNavBlockIndex = navBlocks.length - 1;

      setInitialHeightOfOuterContainer();

      // Only show the last navigation block.
      navBlocks.hide();
      navBlocks.last().show();

      // Since the last block is shown we hide the controlDown button.
      controlDown.hide();

      // If there is no upper level, hide the controlUp button.
      if (navBlocks.length < 2) {
        controlUp.hide();
      }

      initializeClickEvents();
    };

    /**
     * Will be attached to the matchMedia listener and initializes
     * the navigation if the media query matches.
     *
     * @method
     * @param {Object} matchMedia
     * @return {void}
     */
    var mediaQueryEventListener = function(matchMedia) {

      if (matchMedia.matches) {
        initialize();
      }
    };

    /**
     * When the matchMedia() function is available and the media query matches
     * the mobile navigation is initialized.
     *
     * When the query does not match the navigation is not initialized to improve
     * the performance. Additinally a listener is added to the matchMedia object
     * that initializes the mobile navigation when the viewport changes and the
     * media query matches.
     *
     * When the matchMedia() function is not available the navigation is always
     * initialized.
     *
     * @method
     * @return {void}
     */
    var checkMediaQueryAndInitializeEventListener = function() {

      // noinspection JSUnresolvedVariable
      if (typeof window.matchMedia !== 'function') {
        initialize();
        return;
      }

      // noinspection JSUnresolvedFunction
      var matchMedia = window.matchMedia(mediaQuery);

      if (matchMedia.matches) {
        initialize();
      } else {
        matchMedia.addListener(mediaQueryEventListener);
      }

    };

    checkMediaQueryAndInitializeEventListener();
  }

  /**
   * jQuery plugin to initialize the block switching for the mobile navigation.
   */
  $.fn.mobileNavigation = function(options) {

    var instances = [];

    var opts = $.extend({}, $.fn.mobileNavigation.defaults, options);

    this.each(function() {
      instances.push(new MobileNavigation($(this), opts));
    });
  };

  $.fn.mobileNavigation.defaults = {
    effectDuration: 250,
    mediaQueryMaxWidth: 767,
  };
}(jQuery));
