(function ($) {

    "use strict";

    var FoldableQuicksearch = function (searchForm) {

        var searchButton = searchForm.find('.nav-search-button');
        var searchInput = searchForm.find('.nav-search-input');
        var continueCollapsing = false;

        var collapseFieldTimeout = function () {

            // Do not collapse is collapsing was aborted.
            if (!continueCollapsing) {
                return;
            }

            searchForm.addClass('collapsed');
        };

        // We do not collapse the field immediatly to prevent flickering if
        // another event is triggerd that wants to expand the field.
        var collapseField = function () {
            continueCollapsing = true;
            window.setTimeout(collapseFieldTimeout, 10);
        };

        // We unfold the quicksearch by removing the search-collapsed class and the
        // search input field gets the focus.
        var expandField = function (forceExpanding) {

            continueCollapsing = false;

            if (!searchForm.hasClass('collapsed') && !forceExpanding) {
                return;
            }

            // This is a workaround for Firefox because it will not focus the search field.
            window.setTimeout(
                function () {
                    searchInput.focus();
                },
                0
            );

            searchForm.removeClass('collapsed');
        };

        searchForm.find('.nav-search-button, .nav-search-input').on('focus', function () {
            expandField();
        });

        searchForm.find('.nav-search-button').on('mousedown click', function (e) {
            expandField(true);

            // If the user has entered a search term we allow submitting the form.
            if (searchInput.val() === '') {
                e.preventDefault();
            }
        });

        // Collapse the form on escape button.
        searchInput.keyup(function (e) {
            if (e.which === 27) {
                collapseField();
            }
        });

        // When the user leaves the search input field we collapse the form.
        searchInput.on('focusout', function () {
            collapseField();
        });

        // When the user leaves the search button we collapse the form.
        searchButton.on('focusout', function () {
            collapseField();
        });

        // Since focusout is not supported on Safari (touch devices?) we need
        // to listen for touch events on the document.
        $(document).on('touchstart', function (e) {

            // Skip handling if user clicks an search button / input.
            if ($(e.target).hasClass('nav-search-button') || $(e.target).hasClass('nav-search-input')) {
                return;
            }

            // We do not need to do anything if search is already collapsed.
            if (searchForm.hasClass('collapsed')) {
                return;
            }

            collapseField();

            // This closes the keyboard.
            document.activeElement.blur();
            searchInput.blur();
        });
    };

    /**
     * jQuery plugin to initialize the block switching for the mobile navigation.
     */
    $.fn.foldableQuicksearch = function () {

        var instances = [];

        return this.each(function () {
            instances.push(new FoldableQuicksearch($(this)));
        });
    };

}(jQuery));
