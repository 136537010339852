import $ from 'jquery';
import svg4everybody from 'svg4everybody';

import 'bootstrap';
import 'moment';

import '@inttpl_public/JavaScript/SliderRandomImages';
import '@inttpl_public/JavaScript/FoldableQuicksearch';
import '@intdiv_public/JavaScript/ResponsiveImages.js';
import '@intdiv_public/JavaScript/MobileNavigation.js';
import '@intdiv_public/JavaScript/PageOverlay.js';

import 'magnific-popup';

import UrlHashChangeHandler from '@inttpl_private/TypeScript/UrlHashChangeHandler';

svg4everybody();

const body = $('body');
const hashChangeHandler = new UrlHashChangeHandler();

let importCount = 1;
const importFinished = () => {
  importCount--;
  if (importCount === 0) {
    allImportsFinished();
  }
};
const importStarted = () => {
  importCount++
};
const allImportsFinished = () => {
  $(() => hashChangeHandler.init());
};

$(document).on('click.bs.collapse.data-api', '.navbar-toggler', function () {
  // When all toggable navbars are collapsed (hidden) we hide the page overlay.
  // If at least one navbar is visible we show the page overlay.
  if ($('.navbar-toggler.collapsed').length === $('.navbar-toggler').length) {
    $('.page-overlay').fadeOut();
  } else {
    $('.page-overlay').fadeIn();
  }
});

$('.page-overlay').on('click', function() {
  $('.navbar-toggler').click();
  $('.page-overlay').fadeOut();
});

$('.tx-intdiv-mobile-nav').mobileNavigation({
  mediaQueryMaxWidth: 991,
  effectDuration: 0,
});

const lightboxLinks = $('.tx-intdiv-lightbox-link, a.lightbox');
if (lightboxLinks.length > 0) {
  lightboxLinks.magnificPopup({
    type: 'image',
    gallery: {
      enabled: true,
      tPrev: TYPO3.lang.int_mp_gallery_prev_title[0].target,
      tNext: TYPO3.lang.int_mp_gallery_next_title[0].target,
      tCounter: TYPO3.lang.int_mp_gallery_counter[0].target
    },
    image: {
      titleSrc: function(item) {
        return $(item.el).children('.caption').text();
      }
    },
  });
}

const datepicker = $('.flatpickr, .powermail_date');
import {German} from 'flatpickr/dist/l10n/de';
const datepickerConfig = {
  dateFormat: 'Y-m-d',
  altInput: true,
  altFormat: 'd.m.Y',
  allowInput: true,
  locale: German,
  minDate: 'today',
  wrap: true
};
if (datepicker.length > 0) {
  import(/* webpackChunkName: "flatpickr" */ 'flatpickr').then(() => {
    flatpickr(datepicker, datepickerConfig);
  });
}

const enclosureLink = $('.zoo-tgv-enclosure-link');
if (enclosureLink.length) {
  importStarted();
  import(/* webpackChunkName: "enclosure-link-widget" */ '@ext/zoo_tgv/Resources/Private/TypeScript/EnclosureLinks/EnclosureLinksWidget').then(() => {
    importFinished();
  })
}

const imgMapContainer = $('.zoo-tgv-detail-image-map-container');
if (imgMapContainer.length) {
  importStarted();
  import(/* webpackChunkName: "toggle-image-map" */ '@ext/zoo_tgv/Resources/Private/TypeScript/ToggleImageMap').then(() => {
    body.enclosureLinks();
    importFinished();
  });
}

const enclosureOverviewMaps = $('.zoo-tgv-overview-map');
if (enclosureOverviewMaps.length) {
  importStarted();
  import (/* webpackChunkName: "enclosure-overview-map" */ '@ext/zoo_tgv/Resources/Private/TypeScript/EnclosureOverviewMap/OverviewMapWidget').then(() => {
    enclosureOverviewMaps.enclosureOverviewMap({hash: hashChangeHandler.getHash()});
    importFinished();
  });
}

const fullCalendar = $('.tx-zoo-room-reservation-month');
if (fullCalendar.length) {
  importStarted();
  import(/* webpackChunkName: "fullcalendar-adjustments" */ '@ext/inttpl/Resources/Public/JavaScript/FullcalendarAdjustments').then(() => {
    importFinished();
  });
}

const roomreservationForm = $('#tx-zoo-roomreservation-form-start, #tx-zoo-roomreservation-form-end');
if (roomreservationForm.length) {
  importStarted();
  import(/* webpackChunkName: "room-reservation-form" */ '@ext/inttpl/Resources/Public/JavaScript/StartEndFlatpickr').then(() => {
    importFinished();
  });
}

const contentSliders = $('.frame-type-zooslider_slider .slider');
const linkedImageSliders = $('.linked-image-carousel');
const calendarSliders = $('.calendar-carousel');
const newsSliders = $('.news-carousel-responsive');

import(/* webpackChunkName: "sliders" */'@inttpl_private/TypeScript/Sliders.ts').then(({Sliders}) => {
  const initAllSliders = function() {
    Sliders.initSlidersLinkedImage(linkedImageSliders);
    Sliders.initSlidersContent(contentSliders);
    Sliders.initSlidersCalendar(calendarSliders);
    Sliders.initSlidersNews(newsSliders);
  };
  $(() => {
    initAllSliders();

    window.addEventListener('beforeprint', () => {
      linkedImageSliders.slick('unslick');
      contentSliders.slick('unslick');
      calendarSliders.slick('unslick');
      newsSliders.slick('unslick');
    });

    window.addEventListener('afterprint', () => {
      initAllSliders();
    });
  });
});

const $backToTopButton = $('.back-to-top');
const $navbar = $('.navbar.fixed-top');

$(function() {
  if ($navbar.length > 0) {
    $("body").css("margin-top", ($navbar.outerHeight()) + "px");
  }
});

let resizeTimer;

$(window).on('resize', function(e) {

  clearTimeout(resizeTimer);
  resizeTimer = setTimeout(function() {

    let $navbarOpen = $('.navbar-collapse').hasClass('show');

    if ($navbar.length > 0 && !$navbarOpen) {
      $("body").css("margin-top", ($navbar.outerHeight()) + "px");
    }

  }, 250);
});

$('.nav-search-group').prepend('<input name="tx_indexedsearch_pi2[search][sword]" class="form-control nav-search-input" placeholder="Suchbegriff" type="text">');
$('.nav-search-form').foldableQuicksearch();

$(window).scroll(function() {
  let $scroll = $(window).scrollTop();
  $backToTopButton.toggleClass("show", ($scroll > 200));
  $navbar.toggleClass("scrolled", ($scroll > 1));
});

$backToTopButton.on('click', function(e) {
  e.preventDefault();
  $('html, body').animate({scrollTop: 0}, '300');
});
