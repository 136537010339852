import $ from 'jquery';

$(() => {
  const randomImageSlides = $('.frame-type-zooslider_slider .slider .slide-type-images_random');
  randomImageSlides.each(function() {
    const randomImageSlide = $(this);
    const randomImagesContainer = randomImageSlide.find('.random-images');
    const randomImages = randomImagesContainer.find('> div');
    const placeholderImage = randomImageSlide.find('.random-image-placeholder');
    const randomImageIndex = Math.floor(Math.random() * randomImages.length);
    placeholderImage.replaceWith(randomImages[randomImageIndex]);
    randomImagesContainer.remove();
  });
});
