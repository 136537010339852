import jQuery from 'jquery';

(function($) {
  $(document).on('click.bs.collapse.data-api', '[data-toggle="collapse"]', function() {
    // When all toggable navbars are collapsed (hidden) we hide the page overlay.
    // If at least one navbar is visible we show the page overlay.
    if ($('.navbar-toggle.collapsed').length === $('.navbar-toggle').length) {
      $('.page-overlay').hide();
    } else {
      $('.page-overlay').show();
    }
  });
}(jQuery));
